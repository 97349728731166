import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './styles/main.scss';
import './styles/desktop.scss';

import './styles/slick.scss';
import './styles/slick-theme.scss';

// import Checkout from 'pages/Checkout';
// import ProductList from 'pages/ProductList';
// import Categories from 'pages/Categories';
// import Landing from 'pages/Landing';
// import WishList from 'pages/WishList';
// import ClickStreamPLP from 'pages/ClickStreamPLP';
// import Cart from 'pages/Cart';
// import Payment from 'pages/Payment';
// import Confirmation from 'pages/Confirmation';
// import Home from 'pages/Home';
// import Account from 'pages/Account';
// import Profile from 'pages/Account/Profile';
// import ResetPassword from 'pages/Account/ResetPassword';
// import Addresses from 'pages/Account/Addresses';
// import DesignTool from 'pages/DesignTool';
// import CountrySelect from 'pages/Account/CountrySelect';
// import ProductDetail from 'pages/ProductDetail';
// import MyOrders from 'pages/Account/MyOrders';
// import ArchiveOrders from 'pages/Account/ArchiveOrders';
// import ReturnOrders from 'pages/Account/ReturnOrders';
// import ReturnArchiveOrders from 'pages/Account/ReturnArchiveOrders';
// import UnpaidOrders from 'pages/Account/UnpaidOrders';
// import Credits from 'pages/Account/Credits';
// import TransferCredits from 'pages/Account/TransferCredits';
// import Coupons from 'pages/Account/Coupons';
// import cardToken from 'pages/Payment/cardToken';
// import OrderDetails from 'pages/Account/OrderDetails';
// import RateProducts from 'pages/Account/RateProducts';
// import ReturnOrderInit from 'pages/Account/ReturnOrderInit';
// import ReturnOrderDetails from 'pages/Account/ReturnOrderDetails';
// import SavedCards from 'pages/Account/SavedCards';
// import Page404 from 'pages/Error';
// import CreatePassword from 'pages/Account/createPassword';
// import StaticPages from 'pages/StaticPages';

// import Replica from 'containers/Payment/Replica';

import Startup from './Startup';
import { AppProvider } from './context/app';
import ErrorPage from './containers/Error';
import { BASE_PATH } from './constants';

const Home = React.lazy(() =>
  import(/* webpackChunkName: "pages_home" */ 'pages/Home')
);
const Checkout = React.lazy(() =>
  import(/* webpackChunkName: "pages_checkout" */ 'pages/Checkout')
);
const ProductList = React.lazy(() =>
  import(/* webpackChunkName: "pages_productlist" */ 'pages/ProductList')
);
const Categories = React.lazy(() =>
  import(/* webpackChunkName: "pages_categories" */ 'pages/Categories')
);
const Landing = React.lazy(() =>
  import(/* webpackChunkName: "pages_landing" */ 'pages/Landing')
);
const WishList = React.lazy(() =>
  import(/* webpackChunkName: "pages_wishlist" */ 'pages/WishList')
);
const SimilarProducts = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_SimilarProducts" */ 'pages/SimilarProducts'
  )
);
const ClickStreamPLP = React.lazy(() =>
  import(/* webpackChunkName: "pages_clickstreamplp" */ 'pages/ClickStreamPLP')
);
const ArchiveOrders = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_archive_orders" */ 'pages/Account/ArchiveOrders'
  )
);
const Cart = React.lazy(() =>
  import(/* webpackChunkName: "pages_cart" */ 'pages/Cart')
);
const Payment = React.lazy(() =>
  import(/* webpackChunkName: "pages_payment" */ 'pages/Payment')
);
const Confirmation = React.lazy(() =>
  import(/* webpackChunkName: "pages_confirmation" */ 'pages/Confirmation')
);
const Account = React.lazy(() =>
  import(/* webpackChunkName: "pages_account" */ 'pages/Account')
);
const Profile = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_account_profile" */ 'pages/Account/Profile'
  )
);
const SecurityPrivacy = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_account_SecurityPrivacy" */ 'pages/Account/SecurityPrivacy'
  )
);
const ResetPassword = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_account_reset_password" */ 'pages/Account/ResetPassword'
  )
);
const Addresses = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_account_addresses" */ 'pages/Account/Addresses'
  )
);
const DesignTool = React.lazy(() =>
  import(/* webpackChunkName: "pages_design_tool" */ 'pages/DesignTool')
);
const CountrySelect = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_country_select" */ 'pages/Account/CountrySelect'
  )
);
const ProductDetail = React.lazy(() =>
  import(/* webpackChunkName: "pages_product_detail" */ 'pages/ProductDetail')
);
const LogOut = React.lazy(() =>
  import(/* webpackChunkName: "pages_logout" */ 'pages/Account/LogOut')
);
const MyOrders = React.lazy(() =>
  import(/* webpackChunkName: "pages_my_orders" */ 'pages/Account/MyOrders')
);
const ReturnOrders = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_return_orders" */ 'pages/Account/ReturnOrders'
  )
);
const UnpaidOrders = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_unpaid_orders" */ 'pages/Account/UnpaidOrders'
  )
);
const Credits = React.lazy(() =>
  import(/* webpackChunkName: "pages_credits" */ 'pages/Account/Credits')
);
const Coupons = React.lazy(() =>
  import(/* webpackChunkName: "pages_coupons" */ 'pages/Account/Coupons')
);
const DeleteAccount = React.lazy(() =>
  import(/* webpackChunkName: "pages_coupons" */ 'pages/Account/DeleteAccount')
);

const DeleteComfirmPage = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_coupons" */ 'pages/Account/DeleteAccountConfirm'
  )
);
const DeleteNotEligible = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_coupons" */ 'pages/Account/DeleteNotEligible'
  )
);
const cardToken = React.lazy(() =>
  import(/* webpackChunkName: "pages_card_token" */ 'pages/Payment/cardToken')
);
const OrderDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_order_details" */ 'pages/Account/OrderDetails'
  )
);
const RateProducts = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_rate_products" */ 'pages/Account/RateProducts'
  )
);
const ReturnOrderInit = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_return_order_init" */ 'pages/Account/ReturnOrderInit'
  )
);
const ReturnOrderDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_return_order_init" */ 'pages/Account/ReturnOrderDetails'
  )
);
const ReturnArchiveOrders = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_return_order_init" */ 'pages/Account/ReturnArchiveOrders'
  )
);

const SavedCards = React.lazy(() =>
  import(/* webpackChunkName: "pages_saved_card" */ 'pages/Account/SavedCards')
);
const Page404 = React.lazy(() =>
  import(/* webpackChunkName: "pages_error" */ 'pages/Error')
);
const CreatePassword = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_create_password" */ 'pages/Account/createPassword'
  )
);

const StaticPages = React.lazy(() =>
  import(/* webpackChunkName: "pages_static_pages" */ 'pages/StaticPages')
);
const Replica = React.lazy(() =>
  import(/* webpackChunkName: "pages_replica" */ 'containers/Payment/Replica')
);
const TransferCredits = React.lazy(() =>
  import(
    /* webpackChunkName: "pages_transfer_credits" */ 'pages/Account/TransferCredits'
  )
);

// const Page404 = () => {
//   let location = window.location.href;
//   if (location.indexOf('?') > -1) {
//     location += '&mobile=1';
//   } else {
//     location += '?mobile=1';
//   }
// return <Detail href={location} />;

// };

function App() {
  return (
    <AppProvider>
      <Router basename={BASE_PATH}>
        <Startup>
          <Suspense fallback={<div />}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/cardToken" exact component={cardToken} />
              <Route path="/:lang" exact component={Home} />
              <Route path="/:lang/checkout" exact component={Checkout} />
              <Route
                path="/:lang/checkout/payment/address"
                exact
                component={Checkout}
              />
              <Route path="/:lang/checkout/cart" exact component={Cart} />
              <Route path="/:lang/checkout/payment" exact component={Payment} />
              <Route
                exact
                path="/:lang/checkout/payment/confirmation"
                component={Confirmation}
              />
              <Route
                path="/:lang/checkout/payment/retry"
                exact
                component={ErrorPage}
                type="payment-error"
              />
              <Route
                path="/:lang/checkout/payment/replica"
                exact
                component={Replica}
                type="payment-error"
              />
              <Route path="/:lang/list/:category" exact component={Home} />
              <Route path="/:lang/landing/:landing" exact component={Landing} />

              <Route
                path="/:lang/list/:level1/:path(.+)"
                exact
                component={ProductList}
              />
              <Route
                path="/:lang/catalogsearch/result"
                exact
                component={ProductList}
              />

              <Route
                path="/:lang/categories/:level1(.*)"
                exact
                component={Categories}
              />
              <Route path="/:lang/categories" exact component={Categories} />

              <Route path="/:lang/wishlist" exact component={WishList} />
              <Route
                path="/:lang/SimilarProducts/:sku"
                exact
                component={SimilarProducts}
              />
              <Route
                path="/:lang/clickstreamplp"
                exact
                component={ClickStreamPLP}
              />
              <Route path="/:lang/account/profile" exact component={Profile} />
              <Route
                path="/:lang/account/security-privacy"
                exact
                component={SecurityPrivacy}
              />
              <Route path="/:country/:lang/logout" exact component={LogOut} />
              <Route path="/:lang/account/orders" exact component={MyOrders} />
              <Route
                path="/:lang/account/archiveOrders"
                exact
                component={ArchiveOrders}
              />

              <Route path="/:lang/account/credits" exact component={Credits} />
              <Route
                path="/:lang/account/transfer/credits"
                exact
                component={TransferCredits}
              />
              <Route path="/:lang/account/coupons" exact component={Coupons} />
              <Route
                path="/:lang/account/deleteAccount"
                exact
                component={DeleteAccount}
              />
              <Route
                path="/:lang/account/deleteAccount/confirmPage"
                exact
                component={DeleteComfirmPage}
              />
              <Route
                path="/:lang/account/not-eligible"
                exact
                component={DeleteNotEligible}
              />
              <Route path="/:lang/account/faq" exact component={StaticPages} />
              <Route
                path="/:lang/account/privacy"
                exact
                component={StaticPages}
              />
              <Route
                path="/:lang/account/terms"
                exact
                component={StaticPages}
              />
              <Route
                path="/:lang/account/returns"
                exact
                component={StaticPages}
              />
              <Route
                path="/:lang/account/promo"
                exact
                component={StaticPages}
              />

              <Route
                path="/:lang/account/returnOrders"
                exact
                component={ReturnOrders}
              />

              <Route
                path="/:lang/account/returnArchiveOrders"
                exact
                component={ReturnArchiveOrders}
              />
              <Route
                path="/:lang/account/unpaidorders"
                exact
                component={UnpaidOrders}
              />
              <Route
                path="/:lang/account/addresses"
                exact
                component={Addresses}
              />
              <Route
                path="/:lang/account/changepassword"
                exact
                component={ResetPassword}
              />
              <Route
                path="/:lang/account/savedcards"
                exact
                component={SavedCards}
              />
              <Route
                path="/:lang/account/changecountry"
                exact
                component={CountrySelect}
              />
              <Route
                path="/:lang/account/orderview/:orderId"
                exact
                component={OrderDetails}
              />
              <Route
                path="/:lang/account/archive/orderview/:orderId"
                exact
                component={OrderDetails}
              />
              <Route
                path="/:lang/account/orderview/:orderId/returnItem"
                exact
                component={ReturnOrderInit}
              />
              <Route
                path="/:lang/account/returnorderview/:orderId/returnitem/:itemId"
                exact
                component={ReturnOrderDetails}
              />
              <Route
                path="/:lang/account/orderview/rateProducts/:orderId"
                exact
                component={RateProducts}
              />

              <Route
                path="/:lang/account/orderview/:orderId/viewRate/:childSku"
                exact
                component={RateProducts}
              />

              <Route
                path="/:lang/account/orderview/:orderId/editRate/:childSku"
                exact
                component={RateProducts}
              />

              {/* <Route path="/:lang/account/:detail" exact component={Account} /> */}

              <Route path="/:lang/account" exact component={Account} />

              <Route
                path="/:lang/(product*):product"
                exact
                component={ProductDetail}
              />
              {/* BELOW ROUTES ARE HACK DUE TO MAGENTO CANT FORM THE PRODUCT URL CURRECTLY */}
              <Route
                path="/:lang/ae_ar/(product*):product"
                exact
                component={ProductDetail}
              />
              <Route
                path="/:lang/ae_en/(product*):product"
                exact
                component={ProductDetail}
              />
              <Route
                path="/magento-content/:lang/:product"
                exact
                component={ProductDetail}
              />
              <Route
                path="/magento-content/:lang/ae_ar/:product"
                exact
                component={ProductDetail}
              />
              <Route
                path="/magento-content/:lang/ae_en/:product"
                exact
                component={ProductDetail}
              />
              <Route
                path="/:lang/catalog/product/view/id/:productId/*"
                exact
                component={ProductDetail}
              />
              <Route
                path="/:lang/ae_ar/catalog/product/view/id/:productId/*"
                exact
                component={ProductDetail}
              />
              <Route
                path="/:lang/ae_en/catalog/product/view/id/:productId/*"
                exact
                component={ProductDetail}
              />
              <Route
                path="/:lang/customer/account/createPassword"
                exact
                component={CreatePassword}
              />
              <Route
                path="/:lang/customer/account/createPassword/:status"
                exact
                component={CreatePassword}
              />
              <Route exact path="/:lang/designtool" component={DesignTool} />
              {/* END OF PRODUCT URL HACK */}
              <Route path="*" exact component={Page404} />
            </Switch>
          </Suspense>
        </Startup>
      </Router>
    </AppProvider>
  );
}

export default App;
